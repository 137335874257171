<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      :label-width="`${labelWidth}px`"
    >
      <el-form-item label="地址信息" prop="region">
        <el-cascader
          size="large"
          :options="options"
          v-model="ruleForm.region"
          placeholder="请选择省/市/区/街道"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          type="textarea"
          v-model="ruleForm.address"
          rows="3"
          maxlength="120"
          placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息
详细地址长度需要在5-120个汉字或字符，不能包含表情符号"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮政编码" prop="post_code">
        <el-input
          v-model="ruleForm.post_code"
          placeholder="请填写邮编"
        ></el-input>
      </el-form-item>
      <el-form-item label="收货人姓名" prop="contact">
        <el-input
          v-model="ruleForm.contact"
          placeholder="长度不超过25个字符"
          maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item label="成本单元" v-if="userInfo.company_id == 61">
        <el-input
          v-model="ruleForm.cost_unit"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="telephone">
        <el-input
          type="tel"
          v-model="ruleForm.telephone"
          placeholder="中国大陆 +86"
        ></el-input>
      </el-form-item>
      <el-form-item class="checkbox">
        <el-checkbox-group v-model="ruleForm.is_default">
          <el-checkbox
            label="设置为默认收货地址"
            :true-label="1"
            :false-label="0"
            name="default"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          class="white md bg-primary"
          @click="submitForm('ruleForm')"
          :disabled="disabled"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { vxRule } from "@/utils/validator";
// import { regionData } from "element-china-area-data";
import Cache from "@/utils/cache";
export default {
  props: {
    labelWidth: {
      type: [String, Number],
      default: 227,
    },
    // 编辑类型
    type: {
      type: String,
      default: "add",
    },
    // 地址ID
    id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      ruleForm: {
        region: "",
        address: "",
        post_code: "",
        contact: "",
        telephone: "",
        is_default: 0,
        cost_unit: ""
      },
      disabled: false,
      options: [],
      rules: {
        region: vxRule(true, null, "change", "请选择省/市/区/街道"),
        address: vxRule(
          true,
          (val) => {
            return {
              result: val.length <= 120 && val.length >= 5,
              errMsg: "长度在 5-120 个汉字或字符",
            };
          },
          "blur",
          "请输入详细地址"
        ),
        post_code: vxRule(true, null, "blur", "请填写邮编"),
        contact: vxRule(true, null, "blur", "请填写收货人姓名"),
        telephone: vxRule(true, "Mobile", "blur", "请填写手机号码"),
      },
      userInfo: {}, //用户信息
    };
  },
  methods: {
    /**
     * @description 地址编辑
     * @param {String} formName  表单名称
     * @return void
     */
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) return;
        this.disabled = true;
        let { region, is_default } = this.ruleForm;
        let params = {
          ...this.ruleForm,
          province_id: region[0],
          city_id: region[1],
          district_id: region[2],
          is_default,
        };
        // 添加用户收货地址
        let { code, msg } =
          this.type == "add"
            ? await this.$api.addAddressApi(params)
            : await this.$api.updateAddressDetailApi(params);
        if (code == 1) {
          this.$message({
            message: msg,
            type: "success",
          });
          this.$emit("successAddress");
        }
        this.disabled = false;
      });
    },
    /**
     * @description 获取地址详情
     * @returns void
     */
    getAddressDetail() {
      this.$api.getAddressDetailApi({ id: this.id }).then((res) => {
        let { province_id, city_id, district_id, is_default } = res.data;
        this.ruleForm = res.data;
        this.ruleForm.region = [
          province_id.toString(),
          city_id.toString(),
          district_id.toString(),
        ];
        this.ruleForm.is_default = is_default;
      });
    },
    getAllAdressList() {
      this.$api.getAllAdressListApi().then((res) => {
        this.options = res.data;
      });
    },
  },
  created() {
    this.userInfo = Cache.get("userInfo")
    this.getAllAdressList();
    if (this.type == "edit") {
      this.getAddressDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-form {
  .el-cascader {
    width: 100%;
  }

  .el-input__inner,
  .el-textarea__inner {
    resize: none;
    border-radius: 2px;
  }

  .el-form-item__label {
    color: $-color-muted;
  }

  button {
    padding: 10px 50px;
    letter-spacing: 5px;
    cursor: pointer;
    border-radius: 0;
  }

  .checkbox {
    .el-checkbox-group {
      line-height: 1;
    }

    .el-checkbox__label {
      color: $-color-normal;
    }
  }
}
</style>
